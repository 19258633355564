import {
  mainnet,
  base,
  arbitrum,
  xLayer,
  sepolia,
  Chain,
  baseSepolia,
  xLayerTestnet,
  arbitrumSepolia,
} from "viem/chains";
import { chainLogoMap } from "../constants/chains";
import { ChainListItem } from "../utils/types";
import { create } from "zustand";
import { IS_DEV } from "../constants/config";
import { contractMap } from "../constants/deployedContracts";

const getItemFromChainInfo = (chainInfo: Chain) => {
  return {
    label: chainInfo.name,
    id: chainInfo.id,
    src: chainLogoMap[chainInfo.id],
    token: contractMap[chainInfo.id] as `0x${string}`,
    chainInfo,
  };
};

export const mainnetChainList: ChainListItem[] = [
  getItemFromChainInfo(mainnet),
  getItemFromChainInfo(base),
  getItemFromChainInfo(arbitrum),
  getItemFromChainInfo(xLayer),
];

export const testnetChainList: ChainListItem[] = [
  getItemFromChainInfo(sepolia),
  getItemFromChainInfo(baseSepolia),
  getItemFromChainInfo(xLayerTestnet),
  getItemFromChainInfo(arbitrumSepolia),
];

// export const chainList = IS_DEV ? testnetChainList : mainnetChainList;

export const chainList = IS_DEV ? testnetChainList : testnetChainList;

type SelectedChainType = {
  from: ChainListItem;
  setFrom: (item: ChainListItem) => void;
  to: ChainListItem;
  setTo: (item: ChainListItem) => void;
};

export const useSelectedChain = create<SelectedChainType>((set) => {
  return {
    from: chainList[0],
    setFrom: (item: ChainListItem) => {
      set({ from: item });
    },
    to: chainList[1],
    setTo: (item: ChainListItem) => {
      set({ to: item });
    },
    toggle: () => {
      set((state) => {
        const { from, to } = state;
        return { from: to, to: from };
      });
    },
  };
});
