import BigNumber from "bignumber.js";
import { etherUnits, formatEther } from "viem";
import { utils } from "ethers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
const defaultDecimal = etherUnits.wei;

export const truncAddress = (addr: string, fixNum = 4) => {
  if (addr && addr.length > fixNum * 2) {
    return `${addr.substring(0, fixNum)}...${addr.substring(
      addr.length - fixNum
    )}`;
  }
  return addr;
};

export const timestampToUTC = (timestamp: string) => {
  return dayjs
    .unix(parseInt(timestamp))
    .utc()
    .format("MMM D, YYYY, HH:mm:ss, UTC");
};

export const timestampToTime = (timestamp: string) => {
  return new Date(parseInt(timestamp) * 1000).toLocaleString();
};

export const formatEtherValue = (value: string | number | undefined) => {
  try {
    return value && formatEther(BigInt(String(value)));
  } catch (error) {
    console.error({
      error,
    });
  }
};

export const numberFormat = (number: string | number, decimals = 2) => {
  return (
    Math.floor(Number(number) * 10 ** decimals) /
    10 ** decimals
  ).toString();
};

export const formatCoinNum = (
  num: BigNumber.Value, //string  十进制 number | String
  fixNum: number = 2,
  unitName = defaultDecimal
) => {
  return formatNumUnit(
    utils.formatUnits(new BigNumber(num || 0).toFixed(0), unitName),
    fixNum
  );
};

export const formatCoinTimesNum = (
  num: BigNumber.Value,
  timesNum: BigNumber.Value,
  fixNum: number = 2,
  unitName = defaultDecimal
) => {
  return formatNumUnit(
    utils.formatUnits(
      new BigNumber(num || 0).times(timesNum).toFixed(0),
      unitName
    ),
    fixNum
  );
};

const unitArr = [
  { unit: "M", num: new BigNumber(1000000) },
  { unit: "K", num: new BigNumber(1000) },
];
export function formatNumUnit(num: BigNumber.Value, fixNum: number = 2) {
  let bigNum = new BigNumber(num);
  const unit = unitArr.find((item) => bigNum.isGreaterThan(item.num));
  if (unit) {
    bigNum = bigNum.div(unit.num);
  }
  return toFixedZero(bigNum, fixNum) + (unit ? unit.unit : "");
}

export function toFixedZero(num: BigNumber.Value, fixNum: number = 2) {
  const bigNum = new BigNumber(num || 0);
  let res;
  if (bigNum.isLessThan(1)) {
    res = bigNum.toString().match(`^0\\.?0*.{0,${fixNum}}`);
    if (res) {
      res = Number(res);
    }
  }
  if (!res) {
    res = Number(bigNum.toFixed(fixNum));
  }
  return res.toLocaleString(undefined, {
    maximumSignificantDigits: defaultDecimal,
  });
}
