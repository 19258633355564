import { Address } from "viem";

export enum TOKEN_SYMBOL {
  ETH = "ETH",
  DAGI = "DAGI",
}

export type TokenItem = {
  symbol: TOKEN_SYMBOL;
  icon: string;
  address: Address;
};

export type ChainListItem = {
  label: string;
  id: number;
  src: string;
  token: `0x${string}`;
  chainInfo: any;
};
