import { useSelectedChain } from "./useSelectedChain";
import { pad, zeroHash } from "viem";

import { Options } from "@layerzerolabs/lz-v2-utilities";
import {
  useAccount,
  useBalance,
  useConfig,
  useGasPrice,
  useWriteContract,
} from "wagmi";
import { OFTAbi } from "./oftAbi";
import { useWallet } from "./wallet-context";
import {
  readContract,
  waitForTransactionReceipt,
  getTransactionReceipt,
  estimateGas,
} from "@wagmi/core";
import {
  getContractAddressByChainId,
  getEIdByChainId,
} from "../constants/deployedContracts";
import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { getToHash } from "../api/bridge";
import { useQuery } from "@tanstack/react-query";

export type SendDagiParam = {
  recipient: string;
  amount: string;
};

export const useSendDagi = () => {
  const { accountAddress } = useWallet();
  const config = useConfig();
  const selectedChain = useSelectedChain();
  const selectedChainInfo = selectedChain.from.chainInfo;
  const toChainInfo = selectedChain.to.chainInfo;
  const [sendFee, setSendFee] = useState<bigint>();
  const [gasPriceNum, setGasPriceNum] = useState<bigint>();
  const [guid, setGuid] = useState<`0x${string}`>();
  const [refetchCheck, setRefetchCheck] = useState(true);

  const from = selectedChain.from;

  const { data: gasBalance } = useBalance({
    address: accountAddress,
    chainId: from.id,
  });

  const options = Options.newOptions()
    .addExecutorLzReceiveOption(60000, 0)
    .toHex()
    .toString();

  const contractWriter = useWriteContract();
  const { chain } = useAccount();

  const gasPrice = useGasPrice({
    chainId: chain?.id,
  });

  const getEstGas = async ({
    sendFee,
    sendParams,
  }: {
    sendFee: bigint;
    sendParams: any;
  }) => {
    if (gasPrice) {
      const iface = new ethers.utils.Interface(OFTAbi);

      const data = iface.encodeFunctionData(
        "send",
        sendParams
      ) as `0x${string}`;

      const result = await estimateGas(config, {
        chainId: chain?.id,
        to: getContractAddressByChainId(from.chainInfo.id) as `0x${string}`,
        value: sendFee,
        data: data,
      });
      // console.log("result", result);
      if (result && gasPrice.data) {
        const gasPriceNum = gasPrice.data * result;
        setGasPriceNum(gasPriceNum);
        return {
          gasPriceNum,
          gasLimit: result,
        };
      }
    }
  };

  // save
  const transfromDagi = async ({
    params,
    contractAddress,
    sendFee,
  }: {
    params: any;
    contractAddress: string;
    fail?: () => void;
    sendFee: bigint | undefined;
  }) => {
    if (gasBalance && sendFee) {
      const res = await getEstGas({ sendFee, sendParams: params });
      const gasPriceNum = res?.gasPriceNum;
      const gasLimit = res?.gasLimit;
      if (gasPriceNum && gasLimit) {
        // console.log({
        //   gasPriceNum,
        //   sendFee,
        //   value: gasPriceNum + sendFee,
        //   gasBalance,
        //   check: gasBalance.value > ((gasPriceNum + sendFee) as bigint),
        //   params: {
        //     address: contractAddress,
        //     abi: OFTAbi,
        //     functionName: "send",
        //     args: params,
        //     value: sendFee,
        //   },
        //   gasLimit,
        // });
        if (gasBalance.value > ((gasPriceNum + sendFee) as bigint)) {
          const wr = await (contractWriter.writeContractAsync as any)({
            address: contractAddress,
            abi: OFTAbi,
            functionName: "send",
            args: params,
            value: sendFee,
            // // gasPrice:
            // gasLimit: gasLimit + 2000000000n,
          });
          if (wr) {
            // console.log("tx hash: ", wr);
            return wr;
          }
        } else {
          fail && fail();
        }
      }
    }
  };

  const sendDagi = async ({
    params: sendDagiParam,
    fail,
  }: {
    params: SendDagiParam;
    fail?: () => void;
  }) => {
    const tokensToSend = sendDagiParam.amount;

    const destEid = getEIdByChainId(toChainInfo.id);
    // console.log("destEid: ", destEid);

    const sendParam = [
      destEid,
      pad(sendDagiParam.recipient as `0x${string}`),
      tokensToSend,
      tokensToSend,
      options,
      "0x",
      "0x",
    ];

    const contractAddress = getContractAddressByChainId(
      selectedChain.from.chainInfo.id
    );

    const res: any = await (readContract as any)(config, {
      address: contractAddress,
      abi: OFTAbi,
      functionName: "quoteSend",
      args: [sendParam, false],
      chainId: selectedChainInfo.id,
    });

    //  res  nativeFee

    if (res) {
      const nativeFee = res.nativeFee; //ETH

      // render
      setSendFee && setSendFee(nativeFee);

      return transfromDagi({
        fail,
        params: [sendParam, [nativeFee, 0], accountAddress],
        contractAddress,
        sendFee: nativeFee,
      });
    }
    return null;
  };

  // txToHash, setToTxHash
  const { data, refetch } = useQuery({
    queryKey: ["listenForReceipt", guid],
    queryFn: async () => await getToHash(guid),
    enabled: !!accountAddress && !!guid,
    retryDelay: 5000,
    retry: true,
    refetchInterval: refetchCheck ? 5000 : undefined,
  });

  useEffect(() => {
    if (data && data?.ToHash !== undefined && data.ToHash !== zeroHash) {
      setRefetchCheck(false);
    }
  }, [data]);

  return {
    gasSymbol: chain?.nativeCurrency.symbol,
    gasPriceNum,
    sendFee,
    sendDagi,
    toHash: (data?.ToHash !== zeroHash && data?.ToHash) as
      | `0x${string}`
      | undefined,
    listenForReceipt: async ({
      toAddress,
      hash,
      success,
    }: {
      hash: string;
      success?: () => void;
      toAddress: string;
    }) => {
      const receipt = await waitForTransactionReceipt(config, {
        hash: hash as `0x${string}`,
        chainId: selectedChainInfo.id,
        confirmations: 1,
      });

      const guid =
        receipt.logs.length === 5
          ? receipt.logs[receipt.logs.length - 1].topics[1]
          : undefined;

      if (guid) {
        setRefetchCheck(true);
        setGuid(guid);
      }

      if (receipt) {
        success && success();
      }

      return { guid, receipt, toAddress };
    },
  };
};
