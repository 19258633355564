import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { useIsMobileView } from "../../hooks/useIsMobileView";
import styles from "./index.module.scss";

export function CustomModal({
  icon,
  isOpen,
  title,
  description,
  extra,
}: {
  icon?: React.ReactNode;
  isOpen: boolean;
  title?: React.ReactNode;
  description?: string;
  extra?: React.ReactNode;
}) {
  const isMobile = useIsMobileView();

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent
        p={isMobile ? "32px 0 20px" : "44px 0"}
        maxWidth={isMobile ? "calc(100% - 24px)" : "640px"}
        bg="#202020"
        borderRadius="12px"
        color="font.primary"
      >
        <ModalBody
          pb="20px"
          display="flex"
          justifyContent="center"
          flexDir="column"
          alignItems="center"
        >
          {icon ?? (
            <Spinner
              thickness="7px"
              speed="0.65s"
              emptyColor="#414141"
              color="main-brand"
              size="xxl"
            />
          )}
          {title && <Box className={styles.title}>{title}</Box>}
          {description && (
            <Box className={styles.description}>{description}</Box>
          )}
          {extra}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
