import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import arrowRightIcon from "../../../../assets/images/icon/arrow-right.svg";
import { ChainListItem } from "../../../../utils/types";
import { useIsMobileView } from "../../../../hooks/useIsMobileView";

export function SwapCard(props: {
  to?: boolean;
  list: ChainListItem[];
  chain: ChainListItem;
  setChain: (v: ChainListItem) => void;
}) {
  const { to, chain, list, setChain } = props;

  const isMobile = useIsMobileView();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        bg="bg.content"
        borderRadius="8px"
        padding="17px 20px 11px"
        color="rgba(255, 255, 255, 0.8)"
        width="100%"
      >
        <Box textStyle="sm">{to ? "To" : "From"}</Box>
        <Flex
          justifyContent="space-between"
          py="19px"
          cursor="pointer"
          onClick={onOpen}
        >
          <Flex gap="8px" alignItems="center">
            <Image src={chain.src} w="22px" h="22px" />
            <Box fontWeight={500}>{chain.label}</Box>
          </Flex>
          <Image src={arrowRightIcon} />
        </Flex>
      </Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg="main-card"
          borderRadius="12px"
          color="font.primary"
          maxWidth={isMobile ? "calc(100% - 24px)" : "640px"}
        >
          <ModalHeader>Select {to ? "Receiver" : "Sender"} Chain</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="20px">
            <Flex flexWrap="wrap" gap="10px 24px">
              {list.map((item) => {
                return (
                  <Flex
                    key={item.id}
                    padding="8px 8px 8px 16px"
                    bg="bg.content"
                    width={isMobile ? "full" : "calc((100% - 24px) / 2)"}
                    borderRadius="8px"
                    h="52px"
                    alignItems="center"
                    cursor="pointer"
                    gap="8px"
                    _hover={{ opacity: "0.7" }}
                    onClick={() => {
                      setChain(item);
                      onClose();
                    }}
                  >
                    <Image src={item.src} w="24px" h="24px" />
                    {item.label}
                  </Flex>
                );
              })}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
