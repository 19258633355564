import { useSelectedChain } from "./useSelectedChain";
import { useReadContract } from "wagmi";
import { OFTAbi } from "./oftAbi";
import { useWallet } from "./wallet-context";
import { getContractAddressByChainId } from "../constants/deployedContracts";

export const useDagiBalance = () => {
  const { accountAddress } = useWallet();
  const selectedChain = useSelectedChain();
  const ca = getContractAddressByChainId(selectedChain.from.chainInfo.id)

  const balanceQuery = (useReadContract as any)({
    abi: OFTAbi,
    address: ca as `0x${string}`,
    functionName: "balanceOf",
    args: [accountAddress],
  });

  return balanceQuery.data;
};
