import { CustomModal } from "../CustomModal";
import { SuccessCard } from "../Token/Transfer/SuccessCard";
import { useSelectedChain } from "../../hooks/useSelectedChain";

export function CompletedModal({
  isOpen,
  onClose,
  hash,
  setIsSuccess,
  fromAddress,
  toAddress,
  toHash,
  transferAmount,
}: {
  isOpen: boolean;
  title: string;
  hash?: string;
  onClose: () => void;
  fromAddress: string | undefined;
  toAddress: string | undefined;
  setIsSuccess: (v: boolean) => void;
  transferAmount: string | undefined;
  toHash: `0x${string}` | undefined;
}) {
  const { from, to } = useSelectedChain();

  return (
    <CustomModal
      isOpen={isOpen}
      icon={<></>}
      extra={
        <SuccessCard
          toHash={toHash}
          fromAddress={fromAddress}
          toAddress={toAddress}
          from={from}
          to={to}
          setIsSuccess={setIsSuccess}
          onClose={onClose}
          transferAmount={transferAmount}
          hash={hash}
        />
      }
    />
  );
}
