import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const primaryVariant = definePartsStyle({
  tab: {
    border: "1px solid",
    borderRadius: "8px",
    color: "#fff",
    borderColor: "transparent",
    fontSize: 18,
    fontWeight: 500,
    flex: 1,
    padding: "16px 0",
    lineHeight: "18px",
    _selected: {
      borderColor: "var(--chakra-colors-main-brand)",
      color: "main-brand",
      fontWeight: 700,
    },
  },
  tablist: {
    padding: "9px",
    margin: "0 auto",
  },
  tabpanel: {
    padding: "16px 12px",
  },
});

const variants = {
  primary: primaryVariant,
};

export const tabsTheme = defineMultiStyleConfig({ variants });
