import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import styles from "./index.module.scss";
import { Transfer } from "../../components/Token/Transfer";
import { History } from "../../components/Token/History";
import { useDagiBalance } from "../../hooks/useDagiBalance";

export default function Token() {
  useDagiBalance();
  return (
    <Box
      bg="bg.primary"
      color="font.primary"
      className={styles.token}
      pos={"relative"}
    >
      <Tabs variant="primary" className={styles.tab}>
        <TabList
          // pos={"sticky"}
          // top={"64px"}
          bg={"#0A090C"}
          zIndex={2}
          justifyContent={"center"}
          paddingBottom={"20px"}
        >
          <HStack width="342px">
            <Tab>Token</Tab>
            <Tab>History</Tab>
          </HStack>
        </TabList>
        <TabPanels className={styles.content}>
          <TabPanel style={{ height: "100%" }}>
            <div className={styles.contentContainer}>
              <Transfer />
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.contentContainer}>
              <History />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
