import React, { useEffect } from "react";
import classNames from "classnames";

import styles from "./WalletButton.module.scss";
import {
  useAccount,
  useBalance,
  useChainId,
  useConfig,
  useDisconnect,
} from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useClipboard,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useWallet } from "../../hooks/wallet-context";
import { truncAddress } from "../../utils/utils";
import { useAtomValue } from "jotai";
import { ethPriceAtom, okbPriceAtom } from "../../store/price";
import CopyIcon from "../../assets/images/icon/copy.svg";
import { CoinNumShow, USDCoinNumShow } from "../DataShow";

type WalletButtonType = {
  secondary?: boolean;
  className?: string;
  isHeader?: boolean;
};

export const WalletButton = ({
  secondary,
  className = "",
  isHeader,
}: WalletButtonType) => {
  const { accountAddress, isConnected } = useWallet();
  const { disconnect } = useDisconnect();
  // const { onOpen } = useWalletModal();
  const { open } = useWeb3Modal();
  if (isConnected && accountAddress) {
    return (
      <AccountButton>
        <Button
          className={classNames(styles.walletButton, className, {
            [styles.header]: isHeader,
          })}
          variant="primary"
          // onClick={disconnect as any}
        >
          {truncAddress(accountAddress)}
        </Button>
      </AccountButton>
    );
  }
  return (
    <Button
      className={classNames(styles.walletButton, className, {
        [styles.header]: isHeader,
      })}
      variant="primary"
      textTransform="uppercase"
      onClick={() => open()}
    >
      Connect Wallet
    </Button>
  );
};

const AccountButton = (props: { children: any }) => {
  return (
    <div className="">
      <Popover trigger={"click"} placement={"bottom-end"}>
        <PopoverTrigger>{props.children}</PopoverTrigger>
        <PopoverContent
          bg={"#202020"}
          w={"fit-content"}
          border={"none"}
          borderRadius={"8"}
          overflow={"hidden"}
        >
          <ButtonProp />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export function ButtonProp() {
  const { accountAddress, currentChain } = useWallet();
  const { disconnect } = useDisconnect();
  const curChain = currentChain;
  const { data: balance } = useBalance({
    address: accountAddress,
    chainId: curChain?.id,
  });
  const placeholder = "text to be copied...";
  const { onCopy, hasCopied } = useClipboard(accountAddress || "");
  const ethPrice = useAtomValue(ethPriceAtom);
  const okbPrice = useAtomValue(okbPriceAtom);
  const toast = useToast();
  const onClickToExplorer = () => {
    window.open(
      `${curChain?.blockExplorers?.default.url}/address/${accountAddress}`
    );
  };
  useEffect(() => {
    if (hasCopied) {
      toast({
        title: "Copied!",
        status: "success",
        position: "top",
      });
    }
  }, [hasCopied]);
  return (
    <VStack p={5} gap={4} alignItems={"start"} color={"#fff"}>
      <div className="flex items-center">
        <HStack>
          <span className={"text-p1"}>
            {truncAddress(accountAddress ?? "")}
          </span>
          <img src={CopyIcon} className="w-5" onClick={onCopy} />
        </HStack>
      </div>

      <Box>
        <Box fontSize={"sm"}>Balance</Box>
        <Box fontSize={"4xl"}>
          <USDCoinNumShow
            num={balance?.value}
            decimal={balance?.decimals}
            times={
              curChain?.nativeCurrency.symbol === "OKB" ? okbPrice : ethPrice
            }
            unit={false}
          />
        </Box>
        <span className={"text-sm"}>
          <CoinNumShow
            num={balance?.value}
            decimal={balance?.decimals}
            suffix={balance?.symbol}
            unit={false}
          />
        </span>
      </Box>

      <HStack>
        <Button
          variant="outline"
          onClick={onClickToExplorer}
          size={"small"}
          px={3}
          py={1}
          borderWidth={1}
        >
          View on Explorer
        </Button>
        <Button
          variant="outline"
          onClick={() => disconnect()}
          size={"small"}
          px={3}
          py={1}
          borderWidth={1}
        >
          Disconnect
        </Button>
      </HStack>
    </VStack>
  );
}
