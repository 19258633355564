import axios from "axios";

export const getOKBPrice = async () => {
  const queryParameters = { apiKey: "NnQMTUVQ13b5MI3f1VqWAM5LV71QagrP" };
  const ret = await axios.get(`https://api.polygon.io/v1/last/crypto/ETH/USD`, {
    params: queryParameters,
  });
  return ret.data.last.price;
};

export const getEthPrice = async () => {
  const queryParameters = { apiKey: "NnQMTUVQ13b5MI3f1VqWAM5LV71QagrP" };
  const ret = await axios.get(`https://api.polygon.io/v1/last/crypto/ETH/USD`, {
    params: queryParameters,
  });
  return ret.data.last.price;
};

export const getPrices = async (): Promise<
  | {
      price: number;
      symbol: string;
      timestamp: number;
    }[]
  | undefined
> => {
  const ret = await axios.get(
    `https://avpim.tomo.inc/prod/avp_tomoji/v2/common/price`
  );
  return ret.data.result;
};
