export const IS_DEV = process.env.REACT_APP_NODE_ENV !== "test";

export const REACT_APP_BRIDGE_API = IS_DEV
  ? "https://avpim.tomo.inc/dev/bridge_history/"
  : "https://avpim.tomo.inc/prod/bridge_history/";

/* 
.env.production
REACT_APP_NODE_ENV=production
REACT_APP_ANKR_API=https://avpim.tomo.inc/prod/
REACT_APP_BRIDGE_API=https://avpim.tomo.inc/dev/bridge_history/
*/

/* 
.env.test
REACT_APP_NODE_ENV=test
REACT_APP_ANKR_API=https://auth-dev.mobus.workers.dev/
REACT_APP_BRIDGE_API=https://avpim.tomo.inc/dev/bridge_history/
*/

/* 
.env.development

REACT_APP_NODE_ENV=development
REACT_APP_ANKR_API=https://auth-dev.mobus.workers.dev/
REACT_APP_BRIDGE_API=https://avpim.tomo.inc/dev/bridge_history/
*/
