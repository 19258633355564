import { popoverAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const primary = definePartsStyle({
  content: defineStyle({
    padding: "8px",
    bg: "bg.content",
    borderWidth: "0",
    color: "font.primary",
    boxShadow: "0px 4px 12px 0px #00000080",
    borderRadius: "8px",
  }),
  body: {
    border: "none",
    padding: "0",
  },
});

export const popoverTheme = defineMultiStyleConfig({
  variants: { primary },
});
