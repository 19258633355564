import BigNumber from 'bignumber.js'
import { utils } from 'ethers'

export const shortenAddress = (address: string) => {
  if (address?.length <= 11) {
    return address
  }
  return `${address.slice(0, 6)}...${address.slice(-4)}`
}

export const defaultDecimal = 18

export function isNumber(num: any) {
  if (num === undefined || num === null || num === '') {
    return false
  }
  if (typeof num === 'bigint') {
    return true
  }
  return !isNaN(num)
}

export const formatCoinNum = (
  num: BigNumber.Value, //string  十进制 number | String
  fixNum: number = 2,
  unitName = defaultDecimal
) => {
  return formatNumUnit(
    utils.formatUnits(new BigNumber(num || 0).toFixed(0), unitName),
    fixNum
  )
}

export const formatCoinTimesNum = (
  num: BigNumber.Value,
  timesNum: BigNumber.Value,
  fixNum: number = 2,
  unitName = defaultDecimal
) => {
  return formatNumUnit(
    utils.formatUnits(
      new BigNumber(num || 0).times(timesNum).toFixed(0),
      unitName
    ),
    fixNum
  )
}

const unitArr = [
  { unit: 'M', num: new BigNumber(1000000) },
  { unit: 'K', num: new BigNumber(1000) }
]
export function formatNumUnit(num: BigNumber.Value, fixNum: number = 2) {
  let bigNum = new BigNumber(num)
  const unit = unitArr.find(item => bigNum.isGreaterThan(item.num))
  if (unit) {
    bigNum = bigNum.div(unit.num)
  }
  return toFixedZero(bigNum, fixNum) + (unit ? unit.unit : '')
}

export function toFixedZero(num: BigNumber.Value, fixNum: number = 2) {
  const bigNum = new BigNumber(num || 0)
  let res
  if (bigNum.isLessThan(1)) {
    res = bigNum.toString().match(`^0\\.?0*.{0,${fixNum}}`)
    if (res) {
      res = Number(res)
    }
  }
  if (!res) {
    res = Number(bigNum.toFixed(fixNum))
  }
  return res.toLocaleString(undefined, {
    maximumSignificantDigits: defaultDecimal
  })
}
