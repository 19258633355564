import { Box, Flex, Image } from "@chakra-ui/react";
import { Address } from "viem";
import { chainLogoMap } from "../../../constants/chains";
import { useMemo } from "react";
import { chainList } from "../../../hooks/useSelectedChain";
import { truncAddress } from "../../../utils/utils";
import { useIsMobileView } from "../../../hooks/useIsMobileView";

export function FromToAddress({
  fromAddress,
  toAddress,
  fromChainId,
  toChainId,
  titleWidth = "66px",
  gap = "28px",
}: {
  fromAddress: string;
  toAddress: string;
  fromChainId: number;
  toChainId: number;
  titleWidth?: string;
  gap?: string;
}) {
  const isMobile = useIsMobileView();

  const fromChainLabel = useMemo(() => {
    return chainList.find((item) => item.id === fromChainId)?.label;
  }, [fromChainId]);

  const toChainLabel = useMemo(() => {
    return chainList.find((item) => item.id === toChainId)?.label;
  }, [toChainId]);

  return (
    <Flex flexDir="column" gap={gap}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex textStyle="md">
          <Box w={titleWidth} color="#FFFFFFCC">
            From
          </Box>
          {/* isMobile */}
          <Box color="#FFFFFF99">
            {truncAddress(fromAddress, isMobile ? 6 : 10)}
          </Box>
        </Flex>
        <Flex gap="8px" alignItems="center">
          <Image src={chainLogoMap[fromChainId]} w="24px" h="24px" />
          <Box fontSize="16px" lineHeight="20px">
            {fromChainLabel}
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex textStyle="md">
          <Box w={titleWidth} color="#FFFFFFCC">
            To
          </Box>
          <Box color="#FFFFFF99">
            {truncAddress(toAddress, isMobile ? 6 : 10)}
          </Box>
        </Flex>
        <Flex gap="8px" alignItems="center">
          <Image src={chainLogoMap[toChainId]} w="24px" h="24px" />
          <Box fontSize="16px" lineHeight="20px">
            {toChainLabel}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
