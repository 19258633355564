import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle({
  border: "2px solid",
  borderColor: "transparent",
  borderRadius: "8px",
  fontWeight: "700",
  background: "main-brand",
  fontFamily: "Noi Grotesk Trial-Bold",
  color: "#000",
  _hover: {
    background: "#D75004",
  },
  _focus: {
    border: "2px solid #FFFFFF",
  },
  _loading: {
    background: "#D75004",
  },
});

const outline = defineStyle({
  border: "2px solid",
  borderColor: "main-brand",
  borderRadius: "8px",
  fontWeight: "700",
  background: "transparent",
  fontFamily: "Noi Grotesk Trial-Bold",
  color: "main-brand",
  _hover: {
    borderColor: "#D75004",
    background: "transparent",
    color: "#D75004",
  },
  _focus: {
    background: "transparent",
    border: "2px solid #D75004",
    color: "#D75004",
  },
  _loading: {
    borderColor: "#D75004",
    background: "transparent",
    color: "#D75004",
  },
});

const primaryOutline = defineStyle({
  border: "1px solid var(--chakra-colors-main-brand)",
  borderRadius: "8px",
  fontWeight: "700",
  background: "transparent",
  color: "main-brand",
});

const xl = defineStyle({
  fontSize: "20px",
  h: "48px",
  borderRadius: "8px",
});

const customMiddle = defineStyle({
  fontSize: "14px",
  h: "36px",
  borderRadius: "8px",
});

export const buttonTheme = defineStyleConfig({
  variants: { primary, primaryOutline, outline },
  sizes: { xl, customMiddle },
});
