import { Flex, Icon, Input } from "@chakra-ui/react";
import { CustomInput } from "../CustomInput";
import { Address } from "viem";
import { AiOutlineClockCircle } from "react-icons/ai";

export function AddressInput({
  address,
  setAddress,
}: {
  address: string;
  setAddress: (v: string) => void;
}) {
  return (
    <CustomInput
      wrapperStyle={{ gap: "16px", padding: "18px 20px 28px" }}
      leftTop="Recipient Address"
      rightTop={
        <Flex alignItems="center" gap="4px">
          <Icon as={AiOutlineClockCircle} w="18px" h="18px" />
          Estimated Finality: - mins
        </Flex>
      }
      leftBottom={
        <Input
          variant="unstyled"
          placeholder={"Enter the receiver address"}
          fontSize={16}
          fontWeight={500}
          color="font.primary"
          width="full"
          maxLength={42}
          value={address}
          _placeholder={{ color: "#FFFFFF66" }}
          onChange={(e) => {
              const input = e.target.value;
              //仅允许输入字母数字
              const validAddress = /^[a-zA-Z0-9]*$/
              if (validAddress.test(input)) {
                  setAddress(input);
              }else{
                  console.log("Invalid address")
                  e.preventDefault()
              }
          }}
        />
      }
    />
  );
}
