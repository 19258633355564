import { Box, Flex } from "@chakra-ui/react";
import { WalletButton } from "../../../WalletButton2/WalletButton";
import styles from "../../Transfer/index.module.scss";
import { useWallet } from "../../../../hooks/wallet-context";

export function Empty() {
  const { isConnected } = useWallet();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg="main-card"
      height="480px"
      borderRadius="12px"
      flexDir="column"
    >
      <Box fontSize={24} color="#FFFFFF99">
        No Data.
      </Box>
      {!isConnected && (
        <Box mt="64px">
          <WalletButton className={styles.walletButton} />
        </Box>
      )}
    </Flex>
  );
}
