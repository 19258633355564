import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import styles from "./index.module.scss";
import dagiIcon from "../../../assets/images/icon/dagi.png";
import { useEffect, useMemo, useState } from "react";
import { TokenSelect } from "./TokenSelect";
import { SwapCard } from "./SwapCard";
import swapIcon from "../../../assets/images/icon/swap.svg";
import { TOKEN_SYMBOL, TokenItem } from "../../../utils/types";
import { TokenInput } from "./TokenInput";
import { AddressInput } from "./AddressInput";
import { WalletButton } from "../../WalletButton2/WalletButton";
import { CustomModal } from "../../CustomModal";
import { ErrorModal } from "../../ErrorModal";
import { useIsMobileView } from "../../../hooks/useIsMobileView";
import { chainList, useSelectedChain } from "../../../hooks/useSelectedChain";
import { useWallet } from "../../../hooks/wallet-context";
import { useSendDagi } from "../../../hooks/useSendDagi";
import BigNumber from "bignumber.js";
import { formatEtherValue, numberFormat } from "../../../utils/utils";
// import { CompletedModal } from "../../CompletedModal";
import { CompletedModal } from "../../CompletedModal2";
import { useDagiBalance } from "../../../hooks/useDagiBalance";
import { formatEther } from "viem";
import { useBalance } from "wagmi";

export const tokenList: TokenItem[] = [
  { symbol: TOKEN_SYMBOL.DAGI, icon: dagiIcon, address: "0x1" },
];

export function Transfer() {
  const isMobile = useIsMobileView();
  const { accountAddress, isConnected, switchToTargetChain, currentChain } =
    useWallet();
  // const [sendParams, setSendParams] = useState<[any, any, any[]]>();
  const { from, to, setFrom, setTo } = useSelectedChain();

  const {
    sendDagi,
    listenForReceipt,
    gasSymbol,
    toHash,
    gasPriceNum,
    sendFee,
  } = useSendDagi();

  // console.log({
  //   from,
  //   to,
  // });

  const { data: gasBalance } = useBalance({
    address: accountAddress,
    chainId: from.id,
  });

  const toast = useToast();

  const [token, setToken] = useState(tokenList[0].symbol);

  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [recipientAddress, setRecipientAddress] = useState<string>("");
  const [renderToAddress, setRenderToAddress] = useState<string>("");

  const [isSuccess, setIsSuccess] = useState(false);

  const [txHash, setTxHash] = useState<string>();

  const balance = useDagiBalance();

  const {
    isOpen: approveIsOpen,
    onOpen: approveOnOpen,
    onClose: approveOnClose,
  } = useDisclosure();

  const {
    isOpen: confirmIsOpen,
    onOpen: confirmOnOpen,
    onClose: confirmOnClose,
  } = useDisclosure();

  const {
    isOpen: errorIsOpen,
    onOpen: errorOnOpen,
    onClose: errorOnClose,
  } = useDisclosure();

  const {
    isOpen: completedIsOpen,
    onOpen: completedOnOpen,
    onClose: completedOnClose,
  } = useDisclosure();

  const currentToken = useMemo(() => {
    return tokenList.find((item) => item.symbol === token);
  }, [token]);

  const handleSetToken = (v: TOKEN_SYMBOL) => {
    setToken(v);
  };

  const [isSending, setIsSending] = useState(false);

  const toAddress = (renderToAddress || accountAddress) as
    | `0x${string}`
    | undefined;

  // console.log({ recipientAddress, accountAddress, toAddress });

  const sendParam = accountAddress
    ? {
        amount: value,
        recipient: recipientAddress || accountAddress,
        from: from.id,
        to: to.id,
      }
    : undefined;

  const handleTransfer = async () => {
    try {
      console.log("displayBalance", {
        balance,
        value,
        gasBalance,
      });
      if (gasBalance && gasBalance.value <= 0n) {
        toast({
          title: `${gasBalance.symbol} Insufficient balance`,
          status: "error",
          position: "top",
        });
        return;
      }
      if (new BigNumber(value || 0).gt(balance)) {
        toast({
          title: "DAGI Insufficient balance",
          status: "error",
          position: "top",
        });
        return;
      }

      setIsSending(true);
      confirmOnOpen();
      // completedOnOpen();
      if (accountAddress && sendParam) {
        // console.log("Transfer button clicked", sendParam);
        const txHash = await sendDagi({
          params: sendParam,
          fail() {
            gasBalance &&
              toast({
                title: `${gasBalance.symbol} Insufficient balance`,
                status: "error",
                position: "top",
              });
            setIsSending(false);
            confirmOnClose();
          },
        });

        const { toAddress } = await listenForReceipt({
          hash: txHash,
          success: () => {
            confirmOnClose();
            completedOnOpen();
            setValue("");
            setRecipientAddress("");
            setInputValue("");
          },
          toAddress: recipientAddress,
        });

        setRenderToAddress(toAddress);

        setTxHash(txHash);
        setIsSending(false);
        toast({
          title: "Transfer Success",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      console.error("Transfer failed", { error });

      try {
        if (
          (error as any)?.shortMessage.includes(
            "Missing or invalid parameters."
          )
        ) {
          gasBalance &&
            toast({
              title: `${gasBalance.symbol} Insufficient balance`,
              status: "error",
              position: "top",
            });
          confirmOnClose();
          setIsSending(false);
        } else if (
          (error as any)?.shortMessage.includes("User rejected the request")
        ) {
          confirmOnClose();
          setIsSending(false);
        } else if (
          (error as any)?.shortMessage.includes(
            "gas required exceeds allowance"
          )
        ) {
          gasBalance &&
            toast({
              title: `${gasBalance.symbol} Insufficient balance`,
              status: "error",
              position: "top",
            });
          confirmOnClose();
          setIsSending(false);
        } else {
          setIsSending(false);
          confirmOnClose();
          errorOnOpen();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const transferDisabled = !(Number(value) > 0);

  const willReceive =
    Number(value) > 0 ? `${formatEtherValue(value)} DAGI` : `-- DAGI`;

  useEffect(() => {
    if (isConnected && accountAddress && currentChain) {
      const curItem = chainList.find((c) => c.id === currentChain.id);
      if (curItem) {
        setFrom(curItem);
        if (to.id === curItem.id) {
          setTo(from);
        }
      }
    }
  }, [accountAddress, currentChain, isConnected]);

  return (
    <>
      <Flex bg="main-card" flexDir="column" className={styles.transfer}>
        <Box className={styles.header} gap="18px">
          <Flex className={styles.headerLeft}>
            <Box as="span" className={styles.title}>
              Token
            </Box>
            <TokenSelect
              token={token}
              currentToken={currentToken}
              setToken={handleSetToken}
            />
          </Flex>
          {!isMobile && <Box color="main-brand">Secure, fast and low-fee</Box>}
        </Box>

        <Box>
          <Box className={styles.swap}>
            <Box w="full">
              <SwapCard
                chain={from}
                list={chainList.filter((item) => item.id !== to.id)}
                setChain={(value) => {
                  switchToTargetChain(value.id, () => {
                    setFrom(value);
                  });
                }}
              />
            </Box>
            <Box
              className={styles.swapIcon}
              onClick={() => {
                switchToTargetChain(to.id, () => {
                  setFrom(to);
                  setTo(from);
                });
              }}
            >
              <Image src={swapIcon} />
            </Box>
            <Box w="full">
              <SwapCard
                to
                chain={to}
                list={chainList.filter((item) => item.id !== from.id)}
                setChain={setTo}
              />
            </Box>
          </Box>

          <TokenInput
            token={token}
            setValue={setValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </Box>
        <AddressInput
          address={recipientAddress}
          setAddress={setRecipientAddress}
        />

        {isConnected ? (
          <Button
            isLoading={isSending}
            variant="primary"
            isDisabled={transferDisabled}
            className={styles.walletButton}
            onClick={handleTransfer}
          >
            Transfer
          </Button>
        ) : (
          <WalletButton className={styles.walletButton} />
        )}
      </Flex>
      {/* {!isSuccess ? (

      ) : (
        // <SuccessCard from={from} to={to} setIsSuccess={setIsSuccess} />
        <></>
      )} */}

      <ErrorModal
        isOpen={errorIsOpen}
        title="Network error, please try later"
        onClose={errorOnClose}
      />

      <CustomModal
        title="Approving"
        description="Please confirm this transaction in your wallet."
        isOpen={approveIsOpen}
      />

      {/* <CompletedModal
        onClose={completedOnClose}
        isOpen={completedIsOpen}
        title=""
      ></CompletedModal> */}

      <CompletedModal
        onClose={completedOnClose}
        isOpen={completedIsOpen}
        title=""
        hash={txHash}
        fromAddress={accountAddress}
        toAddress={toAddress}
        transferAmount={String(formatEtherValue(value))}
        setIsSuccess={setIsSuccess}
        toHash={toHash}
      ></CompletedModal>

      <CustomModal
        title="Confirming"
        description="Please confirm only if you agree with the transaction."
        isOpen={confirmIsOpen}
        extra={
          <Box w="full" fontSize={isMobile ? 16 : 20} lineHeight="100%">
            <Divider my={isMobile ? "28px" : "48px"} borderColor="#FFFFFF14" />
            <Flex flexDir="column" gap="32px">
              <Flex justifyContent="space-between" fontWeight={500}>
                <Box>You will receive:</Box>
                <Box>{willReceive}</Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                fontWeight={500}
                color="#FFFFFF99"
              >
                <Box>Fee:</Box>
                <Box>
                  {sendFee ? numberFormat(formatEther(sendFee), 6) : "--"}{" "}
                  {gasSymbol}
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                fontWeight={500}
                color="#FFFFFF99"
              >
                <Box>Gas Cost:</Box>
                <Box>
                  {gasPriceNum ? formatEther(gasPriceNum) : "--"} {gasSymbol}
                </Box>
              </Flex>
            </Flex>
          </Box>
        }
      />
    </>
  );
}
