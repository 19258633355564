import { defineChain } from "viem";

export const xLayerTestnet = defineChain({
  id: 195,
  name: "X1 Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "OKB",
    symbol: "OKB",
  },
  rpcUrls: {
    default: { http: ["https://x1testrpc.okx.com"] },
  },
  blockExplorers: {
    default: {
      name: "OKLink",
      url: "https://www.okx.com/web3/explorer/xlayer-test",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 624344,
    },
  },
  testnet: true,
} as unknown as any);

export { xLayerTestnet as x1Testnet };
