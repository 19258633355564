import { EndpointId } from "@layerzerolabs/lz-definitions";
import {
  arbitrum,
  mainnet,
  base,
  xLayer,
  sepolia,
  baseSepolia,
  xLayerTestnet,
  arbitrumSepolia,
} from "wagmi/chains";
import {
  arbitrumAddress,
  baseSepoliaAddress,
  sepoliaAddress,
  xLayerAddress,
} from "./tokenAddress";

/* 
sepoliaAddress, baseSepoliaAddress, xLayerAddress, arbitrumAddress
*/

// DAGI Token Address
export const contractMap: Record<number, string> = {
  [mainnet.id]: "",
  [xLayer.id]: "",
  [base.id]: "",
  [arbitrum.id]: "",

  [sepolia.id]: sepoliaAddress,
  [baseSepolia.id]: baseSepoliaAddress,
  [xLayerTestnet.id]: xLayerAddress,
  [arbitrumSepolia.id]: arbitrumAddress,
};

export const contractIdMap: Record<number, number | null> = {
  [mainnet.id]: null,
  [xLayer.id]: null,
  [base.id]: null,
  [arbitrum.id]: null,

  [sepolia.id]: EndpointId.SEPOLIA_V2_TESTNET,
  [baseSepolia.id]: EndpointId.BASESEP_V2_TESTNET,
  [arbitrumSepolia.id]: EndpointId.ARBSEP_V2_TESTNET,
  [xLayerTestnet.id]: EndpointId.XLAYER_V2_TESTNET,
};

export const getContractAddressByChainId = (chainId: number) => {
  return contractMap[chainId];
};
export const getEIdByChainId = (chainId: number) => {
  return contractIdMap[chainId];
};
export const getChainIdByEId = (eId: number) => {
  return Object.keys(contractIdMap).find(
    (key) => contractIdMap[parseInt(key)] === eId
  );
};
