import axios from "axios";
import { HistoryResult, ListRequest } from "./type";
import { REACT_APP_BRIDGE_API } from "../constants/config";

const avpimApi = axios.create({ baseURL: REACT_APP_BRIDGE_API });

// https://avpim.tomo.inc/dev/bridge_history/history/:address?page=1&page_size=20

/**
 * @description: history
 * @param {string} tomojiId
 * @return {*}
 */
export const getHistory = async ({
  address,
  page,
  page_size = 20,
}: ListRequest & { address: string }): Promise<{
  data: HistoryResult[];
  total: number;
}> => {
  // const ret = await api.get(`tomoji/${tomojiId}`);
  const ret = await avpimApi.get(
    `history/${address}?page=${page}&page_size=${page_size}`
  );
  return ret.data.data;
};

export const getToHash = async (guid: `0x${string}` | undefined) => {
  const ret = await avpimApi.get(`guid/${guid}`);
  return ret.data.data;
};
// /guid/:guid
