import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import styles from "./index.module.scss";

export function CustomInput(props: {
  leftTop?: React.ReactNode;
  rightTop?: React.ReactNode;
  leftBottom?: React.ReactNode;
  rightBottom?: React.ReactNode;
  wrapperStyle?: any;
}) {
  const { leftTop, rightTop, leftBottom, rightBottom, wrapperStyle } = props;

  return (
    <Flex
      bg="bg.content"
      borderRadius="8px"
      flexDir="column"
      padding="18px 20px"
      gap="6px"
      {...wrapperStyle}
    >
      <Flex className={styles.top}>
        <Box>{leftTop}</Box>
        <Box>{rightTop}</Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        color="#FFFFFF4D"
        fontSize={14}
      >
        <Box flexGrow="1">{leftBottom}</Box>
        <Box>{rightBottom}</Box>
      </Flex>
    </Flex>
  );
}
