// @ts-nocheck
import { BigNumber } from 'bignumber.js'
import {
  defaultDecimal,
  formatNumUnit,
  isNumber,
  toFixedZero
} from '../utils/helper'
import { utils } from 'ethers'
import React from 'react'

type NumShowProps = {
  num: BigNumber.Value | bigint | undefined
  unit?: boolean
  fixNum?: number
  prefix?: string
  suffix?: string
}

type CoinNumShowProps = NumShowProps & {
  times?: BigNumber.Value | BigNumber.Value[]
  decimal?: number
}

function timesNum(
  num: BigNumber,
  timesList: BigNumber.Value | BigNumber.Value[] | undefined
) {
  if (!timesList) {
    return num
  }
  if (!Array.isArray(timesList)) {
    timesList = [timesList]
  }
  return timesList.reduce((num: BigNumber, item) => {
    return num.times(item)
  }, num)
}

export const NumShow = React.memo(function NumShow({
  num,
  fixNum = 2,
  prefix,
  suffix,
  unit = true
}: NumShowProps) {
  return isNumber(num)
    ? `${prefix || ''}${(unit ? formatNumUnit : toFixedZero)(num, fixNum)}${suffix ? ` ${suffix}` : ''}`
    : '-'
})

export const CoinNumShow = React.memo(function CoinNumShow({
  num,
  times,
  decimal = defaultDecimal,
  ...other
}: CoinNumShowProps) {
  return (
    <NumShow
      num={
        isNumber(num)
          ? utils.formatUnits(
              timesNum(new BigNumber(num), times).toFixed(0),
              decimal
            )
          : num
      }
      {...other}
    />
  )
})

export function USDNumShow({ prefix = '$', ...other }: NumShowProps) {
  return <NumShow prefix={prefix} {...other} />
}

export function USDCoinNumShow({ prefix = '$', ...other }: CoinNumShowProps) {
  return <CoinNumShow prefix={prefix} {...other} />
}

export function ETHNumShow({ suffix = 'ETH', ...other }: NumShowProps) {
  return <NumShow suffix={suffix} {...other} />
}

export function ETHCoinNumShow({ suffix = 'ETH', ...other }: CoinNumShowProps) {
  return <CoinNumShow suffix={suffix} {...other} />
}
