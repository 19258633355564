import { Box, Button, Image } from "@chakra-ui/react";
import { CustomModal } from "../CustomModal";
import errorIcon from "../../assets/images/icon/error.svg";

export function ErrorModal({
  isOpen,
  title,
  onClose,
}: {
  isOpen: boolean;
  title: string;
  onClose: () => void;
}) {
  return (
    <CustomModal
      isOpen={isOpen}
      icon={<Image src={errorIcon} width="80px" height="80px" />}
      title={
        <Box color="font.primary" fontSize="24px" textAlign="center">
          {title}
        </Box>
      }
      extra={
        <Button
          variant="primary"
          w="280px"
          size="xl"
          mt="24px"
          onClick={onClose}
        >
          CLOSE
        </Button>
      }
    />
  );
}
