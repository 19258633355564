import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { DEFAULT_ROUTE, ROUTES } from "./constants/routes";
import WalletProvider from "./hooks/wallet-context";
import { Web3ModalProvider } from "./hooks/web3-modal-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      ...ROUTES.map((item) => ({
        path: item.path,
        element: item.component,
      })),
      { path: "", element: <Navigate to={DEFAULT_ROUTE} replace /> },
      { path: "*", element: <Navigate to={DEFAULT_ROUTE} replace /> },
    ],
  },
]);

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Web3ModalProvider>
        <WalletProvider>
          <RouterProvider router={router} />
        </WalletProvider>
      </Web3ModalProvider>
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
