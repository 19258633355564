import { Box, Flex, Image, Link, Spinner, useToast } from "@chakra-ui/react";
import copyIcon from "../../../assets/images/icon/copy.svg";
import { useIsMobileView } from "../../../hooks/useIsMobileView";
import { truncAddress } from "../../../utils/utils";
import { useConfig } from "wagmi";
import { zeroAddress, zeroHash } from "viem";

export function TransactionHash({
  hash,
  chainId,
  title,
  isHashLoading = false,
}: {
  hash: string | undefined;
  chainId: number;
  title: string;
  isHashLoading?: boolean;
}) {
  const isMobile = useIsMobileView();
  const toast = useToast();
  const { chains } = useConfig();
  const curChain = chains.find((c) => c.id === chainId);
  const href = hash && `${curChain?.blockExplorers?.default?.url}/tx/${hash}`;
  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const hashRender = (
    <>
      <Link href={href} target={"_blank"}>
        {hash && truncAddress(hash, 6)}
      </Link>
      <Image
        src={copyIcon}
        cursor="pointer"
        onClick={() => {
          hash && copyTextToClipboard(hash);
          toast({
            title: "Copied!",
            status: "success",
            duration: 1000,
            isClosable: true,
            position: "top",
          });
        }}
      />
    </>
  );

  return (
    <Flex
      justifyContent="space-between"
      flexDir={isMobile ? "column" : "row"}
      gap="8px"
      textStyle="md"
    >
      <Box color="#FFFFFF99">{title}</Box>
      <Flex textDecoration="underline" gap="6px">
        {isHashLoading ? (
          hash && hash !== zeroHash ? (
            hashRender
          ) : (
            <div
              style={{
                width: "9rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner
                speed="0.65s"
                emptyColor="#414141"
                color="main-brand"
                size="xs"
              />
            </div>
          )
        ) : (
          hashRender
        )}
      </Flex>
    </Flex>
  );
}
