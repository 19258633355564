import { Box, Button, Flex, Icon, Input } from "@chakra-ui/react";
import { CustomInput } from "../CustomInput";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { TOKEN_SYMBOL } from "../../../../utils/types";
import { useIsMobileView } from "../../../../hooks/useIsMobileView";
import { useDagiBalance } from "../../../../hooks/useDagiBalance";
import { formatEther, parseEther } from "viem";
import { useState } from "react";

export function TokenInput({
  token,
  setValue,
  inputValue,
  setInputValue,
}: {
  token: TOKEN_SYMBOL;
  setValue: (v: string) => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const isMobile = useIsMobileView();
  const balance = useDagiBalance();
  const displayBalance = balance ? formatEther(balance) : "0.00000";

  return (
    <CustomInput
      wrapperStyle={isMobile ? { gap: "30px" } : {}}
      leftTop={
        <Flex gap="8px" alignItems="center">
          <Box>Balance: {displayBalance}</Box>
          <Icon as={AiOutlineInfoCircle} w="18px" h="18px"></Icon>
        </Flex>
      }
      // rightTop={`Maximum: 2.0 ${token}`}
      leftBottom={
        <Input
          value={inputValue}
          type="tel"
          variant="unstyled"
          placeholder="0.00"
          fontSize={24}
          fontWeight={500}
          maxLength={36}
          color="font.primary"
          _placeholder={{ color: "#FFFFFF66" }}
          onChange={(e) => {
            // (/^0*(?:\d+|\d+\.\d{0,18})$/
            // /^0*$|^(?:0|[1-9]\d*)(?:\.\d{0,18})?$/
            if (
              !e.target.value ||
              /^(\d+)\.?(\d{0,18})?$/.test(e.target.value)
            ) {
              const parsedValue = parseEther(e.target.value);
              if (parsedValue >= 0n) {
                setValue(parseEther(e.target.value).toString());
                setInputValue(e.target.value);
              } else {
                console.error("Invalid or non-positive amount");
              }
            } else {
              console.error("Invalid number format");
            }
          }}
        />
      }
      rightBottom={
        <Button
          border="1px solid #FFFFFF1A"
          height="36px"
          variant="outline"
          color="main-brand"
          padding="11px 13px"
          _hover={{ bg: "transparent" }}
          onClick={() => {
            setValue(balance);
            setInputValue(displayBalance);
          }}
        >
          Max
        </Button>
      }
    />
  );
}
