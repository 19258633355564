import {
  Box,
  Button,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { tokenList } from "../index";
import checkIcon from "../../../../assets/images/icon/check.svg";
import arrowRightIcon from "../../../../assets/images/icon/arrow-right.svg";
import { TOKEN_SYMBOL, TokenItem } from "../../../../utils/types";

export function TokenSelect({
  token,
  currentToken,
  setToken,
}: {
  token: TOKEN_SYMBOL;
  currentToken?: TokenItem;
  setToken: (v: TOKEN_SYMBOL) => void;
}) {
  return (
    <Popover variant="primary">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              leftIcon={<Image src={currentToken?.icon} width='26px' />}
              rightIcon={
                <Image
                  src={arrowRightIcon}
                  transition="0.2s"
                  transform={isOpen ? "rotate(-90deg)" : ""}
                />
              }
              bg="bg.content"
              color="font.primary"
              height="46px"
              borderRadius="8px"
              fontWeight={500}
              padding="10px"
              _hover={{ bg: "bg.content" }}
              _focus={{ bg: "bg.content" }}
              _active={{ bg: "bg.content" }}
            >
              {currentToken?.symbol}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="220px">
              <PopoverBody>
                {tokenList.map((item) => {
                  return (
                    <Flex
                      key={item.symbol}
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => {
                        setToken(item.symbol);
                        onClose();
                      }}
                      _hover={{ bg: "main-card" }}
                      padding="8px 12px"
                      cursor="pointer"
                      borderRadius="8px"
                    >
                      <Flex gap="10px">
                        <Image src={item.icon} width='26px'/>
                        <Box>{item.symbol}</Box>
                      </Flex>
                      {item.symbol === token && <Image src={checkIcon} />}
                    </Flex>
                  );
                })}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
}
