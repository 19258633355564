import React from "react";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  arbitrum,
  arbitrumSepolia,
  base,
  baseSepolia,
  bsc,
  mainnet,
  sepolia,
} from "viem/chains";
import { xLayerMainnet } from "../constants/xLayerMainnet";
import { IS_DEV } from "../constants/config";
import { chainList } from "./useSelectedChain";
import { xLayerTestnet } from "../constants/chainsConfig/xLayerTestnet";

// export const SUPPORTED_CHAINS = IS_DEV
//   ? ([sepolia, baseSepolia, arbitrumSepolia] as const)
//   : ([mainnet, base, arbitrum] as const);

export const SUPPORTED_CHAINS = IS_DEV
  ? ([sepolia, baseSepolia, arbitrumSepolia, xLayerTestnet] as const)
  : ([sepolia, baseSepolia, arbitrumSepolia, xLayerTestnet] as const);

// ? ([mainnet, base, arbitrum, xLayerMainnet] as const)
// : ([sepolia, baseSepolia, arbitrumSepolia, xLayerTestnet] as const);

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "35406fc62ee8be42c182da950953a352";

// 2. Create wagmiConfig
const metadata = {
  name: "Prosperex",
  description: "Prosper Exchange",
  url: "https://trade.prosperex.xyz/trade", // origin must match your domain & subdomain
  icons: ["https://trade.prosperex.xyz/logo.png"],
};

const config = defaultWagmiConfig({
  chains: SUPPORTED_CHAINS,
  projectId,
  metadata,
  enableWalletConnect: true,
  enableInjected: true,
  enableEIP6963: true,
  enableCoinbase: true,
});

const chainImages = () => {
  const chainImage: { [key: number]: string } = {};
  Object.keys(chainList).map((key) => {
    chainImage[chainList[Number(key)].id] = chainList[Number(key)].src;
  });

  return chainImage;
};

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  // enableAnalytics: false, // Optional - defaults to your Cloud configuration
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  themeMode: "dark",
  themeVariables: {
    "--w3m-color-mix": "#000000",
    "--w3m-color-mix-strength": 40,
    "--w3m-accent": "#dc5103",
  },
  allowUnsupportedChain: false,
  chainImages: chainImages(),
});

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

export function Web3ModalProvider({ children }: { children: React.ReactNode }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
