import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./ButtonTheme";
import { tabsTheme } from "./TabsTheme";
import { spinnerTheme } from "./SpinnerTheme";
import { popoverTheme } from "./PopoverTheme";
import { drawerTheme } from "./DraweTheme";

const semanticTokens = {
  colors: {
    bg: {
      primary: "#0A090C",
      content: "#2E2E2E",
    },
    font: {
      primary: "#fff",
    },
    "main-brand": "#FF5C00",
    "main-card": "#1A1A1A",
    green: "#17CF82",
  },
};

const theme = extendTheme({
  semanticTokens,
  components: {
    Button: buttonTheme,
    Tabs: tabsTheme,
    Spinner: spinnerTheme,
    Popover: popoverTheme,
    Drawer: drawerTheme,
  },
  textStyles: {
    sm: {
      fontSize: "14px",
      lineHeight: "14px",
    },
    md: {
      fontSize: "16px",
      lineHeight: "16px",
    },
  },
});

export default theme;
