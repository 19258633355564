import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { ButtonProp, WalletButton } from "../WalletButton2/WalletButton";

const MobileDawer = ({
  isOpen,
  onClose,
  btnRef,
}: {
  isOpen: boolean;
  onClose: () => void;
  btnRef: React.MutableRefObject<any>;
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      size={"full"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color={"#FFFFFF"} />
        {/* <DrawerHeader></DrawerHeader> */}

        <DrawerBody
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <WalletButton /> */}
          <ButtonProp />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileDawer;
