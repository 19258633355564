import { IS_DEV } from "./config";

const sepoliaAddress = IS_DEV
  ? "0x0e3F5473693C74E33eE98bede781995De25B3AF0"
  : "0xfA1BE6775732e0caBBb5026D09Ad01B09991f8Df";

const baseSepoliaAddress = IS_DEV
  ? "0xF8b37D6cA9180dc7Fa82D54eaE968385879dAF17"
  : "0xfA1BE6775732e0caBBb5026D09Ad01B09991f8Df";

const xLayerAddress = IS_DEV
  ? "0x49303e74334dF0E360369A25C5076aEA5B583473"
  : "0xfA1BE6775732e0caBBb5026D09Ad01B09991f8Df";

const arbitrumAddress = IS_DEV
  ? "0x8fAddd6aB4054E3C579B5c7eaEb09842fE06a17A"
  : "0xfA1BE6775732e0caBBb5026D09Ad01B09991f8Df";

export { sepoliaAddress, baseSepoliaAddress, xLayerAddress, arbitrumAddress };
