import { Box, Button, Divider, Flex, Image } from "@chakra-ui/react";
import { ChainListItem } from "../../../../utils/types";
import completedIcon from "../../../../assets/images/icon/completed.svg";
import { FromToAddress } from "../../FromToAddress";
import { TransactionHash } from "../../TransactionHash/TransactionHash";
import { useIsMobileView } from "../../../../hooks/useIsMobileView";
import { useChainId, useConfig } from "wagmi";
import { useEffect } from "react";

export function SuccessCard({
  from,
  to,
  setIsSuccess,
  onClose,
  fromAddress,
  toAddress,
  transferAmount,
  hash,
  toHash,
}: {
  from: ChainListItem;
  to: ChainListItem;
  setIsSuccess: (v: boolean) => void;
  onClose: () => void;
  fromAddress: string | undefined;
  toAddress: string | undefined;
  transferAmount: string | undefined;
  hash: string | undefined;
  toHash: `0x${string}` | undefined;
}) {
  const isMobile = useIsMobileView();
  const { chains } = useConfig();
  const chainId = useChainId();
  const curChain = chains?.find((c) => c.id === chainId);
  const href = `${curChain?.blockExplorers?.default?.url}/tx/${hash}`;

  useEffect(() => {
    sessionStorage.setItem(
      hash as string,
      JSON.stringify({
        time: new Date().toLocaleString(),
        toAddress: toAddress?.toLowerCase(),
        toChainId: to.id,
      })
    );
  }, []);

  return (
    <Flex
      flexDir="column"
      borderRadius="12px"
      alignItems="center"
      width={"100%"}
    >
      <Flex gap="6px" alignItems="flex-end">
        <Box fontSize="30px" lineHeight="100%" fontWeight={600}>
          {transferAmount}
        </Box>
        <Box fontSize={20} lineHeight="20px">
          DAGI
        </Box>
      </Flex>
      <Flex
        gap="6px"
        mt="24px"
        alignItems="center"
        textStyle="md"
        color="green"
      >
        <Image src={completedIcon} w="20px" h="20px" />
        {toHash ? <>Completed</> : <>Send</>}
      </Flex>
      <Divider mt="28px" mb="36px" borderColor="#FFFFFF14" />
      <Flex gap="28px" flexDir="column" w="full">
        <FromToAddress
          fromAddress={fromAddress ?? "--"}
          fromChainId={from.id}
          toAddress={toAddress ?? "--"}
          toChainId={to.id}
          titleWidth={isMobile ? "45px" : "66px"}
        />
        <TransactionHash
          title="Sender Transaction Hash:"
          hash={hash ?? "--"}
          chainId={from.id}
        />
        <TransactionHash
          title="Receiver Transaction Hash:"
          hash={toHash}
          chainId={to.id}
          isHashLoading
        />
      </Flex>
      {!isMobile ? (
        <Flex gap="16px" mt="64px" w="full">
          <Button
            as={"a"}
            target={"_blank"}
            href={href}
            flex={1}
            size="xl"
            variant="primaryOutline"
            textTransform="uppercase"
          >
            View On explorer
          </Button>
          <Button
            flex={1}
            size="xl"
            variant="primary"
            textTransform="uppercase"
            onClick={() => onClose()}
          >
            Transfer Again
          </Button>
        </Flex>
      ) : (
        <Box w="full" mt="40px">
          <Button
            w="full"
            size="xl"
            variant="primary"
            textTransform="uppercase"
            mb="16px"
            onClick={() => onClose()}
          >
            Transfer Again
          </Button>
          <Button
            as={"a"}
            target={"_blank"}
            href={href}
            w="full"
            size="xl"
            variant="primaryOutline"
            textTransform="uppercase"
          >
            View On explorer
          </Button>
        </Box>
      )}
    </Flex>
  );
}
