import mainnetIcon from "../assets/images/chains/1.svg";
import xLayerLogo from "../assets/images/chains/196.svg";
import baseIcon from "../assets/images/chains/8453.png";
import arbitrumIcon from "../assets/images/chains/42161.png";
import { arbitrum, mainnet, base, xLayer, sepolia, baseSepolia, xLayerTestnet, arbitrumSepolia } from 'wagmi/chains';

export const chainLogoMap: Record<number, string> = {
  [mainnet.id]: mainnetIcon,
  [sepolia.id]: mainnetIcon,
  [xLayer.id]: xLayerLogo,
  [xLayerTestnet.id]: xLayerLogo,
  [base.id]: baseIcon,
  [baseSepolia.id]: baseIcon,
  [arbitrum.id]: arbitrumIcon,
  [arbitrumSepolia.id]: arbitrumIcon,
};
