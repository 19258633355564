import { Box, Divider, Flex, Image } from "@chakra-ui/react";
import { Empty } from "./Empty";
import dagiIcon from "../../../assets/images/icon/dagi.png";
import completeIcon from "../../../assets/images/icon/completed.svg";
import { FromToAddress } from "../FromToAddress";
import { TransactionHash } from "../TransactionHash/TransactionHash";
import { useIsMobileView } from "../../../hooks/useIsMobileView";
import styles from "./index.module.scss";
import { useQuery } from "@tanstack/react-query";
import { formatEther, zeroHash } from "viem";
import { timestampToTime, timestampToUTC } from "../../../utils/utils";
import { getHistory } from "../../../api/bridge";
import { useWallet } from "../../../hooks/wallet-context";
import { useEffect, useMemo, useState } from "react";
import { HistoryResult } from "../../../api/type";

export function History() {
  const { accountAddress } = useWallet();

  const [page, setPage] = useState(1);

  const { data, refetch } = useQuery({
    queryKey: ["GetLocations2"],
    queryFn: async () =>
      await getHistory({ address: accountAddress ?? "", page }),
    enabled: !!accountAddress && !!page,
    refetchInterval: 30000,
  });

  useEffect(() => {
    refetch();
  }, []);

  // const OFTSentQuery = useQuery({
  //   queryKey: ["GetLocations"],
  //   queryFn: () => ethSepoliaApolloClient.query({ query: GetOFTSents }),
  // });

  const oftSents: HistoryResult[] = data?.data || [];
  const list = oftSents.map((oftSent) => ({
    /*
  AmountReceivedLD: "";
  AmountSentLd: "";
  DstChainID: ;
  DstEid: ;
  DstTime: ;
  From: "";
  FromHash: "";
  Guid: "";
  ID: ;
  SrcChainID: ;
  SrcEid: ;
  SrcTime: ;
  To: ;
  ToHash: ;
     */
    id: oftSent.ID,
    amount: formatEther(BigInt(oftSent.AmountSentLd)),
    token: "DAGI",
    src: dagiIcon,
    state: oftSent.ToHash && oftSent.ToHash !== zeroHash ? "Completed" : "Send",
    // time: timestampToTime(oftSent.DstTime.toString()),
    time: timestampToUTC(oftSent.SrcTime.toString()),
    fromAddress: oftSent.From,
    fromChainId: oftSent.SrcChainID,
    toAddress: oftSent.To,
    toChainId: oftSent.DstChainID,
    toHash: oftSent.ToHash,
    fromHash: oftSent.FromHash,
  }));

  return (
    <>
      {list.length ? (
        <>
          {list.map((item) => {
            return <HistoryItem key={item.id} hItem={item} />;
          })}
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}

function HistoryItem({ hItem }: { hItem: any }) {
  const isMobile = useIsMobileView();
  const isSendState = /^0x0+$/.test(hItem.fromHash);
  const isReceiverState = /^0x0+$/.test(hItem.toHash);
  const item = useMemo(() => {
    if (isSendState) {
      let tempData = {};
      if (sessionStorage.getItem(hItem.fromHash)) {
        tempData = JSON.parse(sessionStorage.getItem(hItem.fromHash) as string);
      }
      return {
        ...hItem,
        state: "Send",
        ...tempData,
      };
    }
    return hItem;
  }, [hItem]);

  return (
    <Box className={styles.card} key={item.id} bg="main-card">
      <Flex justifyContent="space-between" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex gap="12px" alignItems="center">
            <Box fontWeight={600} fontSize="20px" lineHeight="20px">
              {item.amount}
            </Box>
            <Flex gap="4px">
              <Image src={item.src} w="24px" h="24px" />
              <Box>{item.token}</Box>
            </Flex>
          </Flex>
          <Flex flexDir="column" alignItems="flex-end" gap="10px">
            <Flex
              gap="6px"
              alignItems="center"
              color="green"
              lineHeight="16px"
              textStyle="md"
            >
              <Image src={completeIcon} w="16px" h="16px" />
              {item.state}
            </Flex>
            {!isMobile && (
              <Box fontSize="14px" lineHeight="16px" color="#FFFFFF99">
                {item.time}
              </Box>
            )}
          </Flex>
        </Flex>
        {isMobile && (
          <Box fontSize="14px" lineHeight="16px" color="#FFFFFF99" mt="12px">
            {item.time}
          </Box>
        )}
        <Divider
          borderColor="#FFFFFF14"
          mt={isMobile ? "24px" : "10px"}
          mb="20px"
        />
        <FromToAddress
          fromAddress={item.fromAddress}
          toAddress={item.toAddress}
          fromChainId={item.fromChainId}
          toChainId={item.toChainId}
          titleWidth="53px"
          gap="20px"
        />
        {(!isSendState || !isReceiverState) && (
          <Box className={styles.transaction} bg="bg.content">
            {!isSendState && (
              <TransactionHash
                title="Sender Transaction Hash:"
                hash={item.fromHash}
                chainId={item.fromChainId}
              />
            )}
            {/* {!isReceiverState && ( */}
            <TransactionHash
              title="Receiver Transaction Hash:"
              hash={item.toHash}
              chainId={item.toChainId}
              isHashLoading
            />
            {/* )} */}
          </Box>
        )}
      </Flex>
    </Box>
  );
}
