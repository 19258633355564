import React, { useCallback, useEffect, useMemo } from "react";
import { useAccount, useConfig, useSwitchChain } from "wagmi";
import { Chain } from "wagmi/chains";
import { SUPPORTED_CHAINS } from "./web3-modal-context";
import { Address } from "viem";
import { useToast } from "@chakra-ui/react";

interface WalletContextType {
  accountAddress: Address | undefined;
  isConnected: boolean;
  currentChain: Chain | undefined;
  targetChain: Chain;
  isCorrectChain: boolean;
  isPendingSwitchChain: boolean;
  switchToTargetChain: (chainId: number, cb?: () => void) => void;
}

const WalletContext = React.createContext<WalletContextType>({
  accountAddress: undefined,
  isConnected: false,
  currentChain: undefined,
  targetChain: SUPPORTED_CHAINS[0],
  isCorrectChain: true,
  isPendingSwitchChain: false,
  switchToTargetChain: () => {},
});

export const useWallet = () => React.useContext(WalletContext);

const isChainSupported = (
  targetChain: Chain | undefined,
  supportedChains: readonly Chain[] | undefined
) => {
  if (!(targetChain && supportedChains)) {
    return false;
  }
  return supportedChains.some((chain) => chain.id === targetChain.id);
};

const useProviderWallet = () => {
  const toast = useToast();
  // const doesGuardNetwork = false;
  const {
    address: accountAddress,
    isConnected,
    chain: currentChain,
  } = useAccount();

  const { switchChainAsync, isPending: isPendingSwitchChain } =
    useSwitchChain();

  const { chains: supportedChains } = useConfig();

  // 1. currentChain
  // 2. targetChainId in sessionStorage
  // 3. defaultChain
  const getDefaultTargetChain = () => {
    if (!!currentChain && isChainSupported(currentChain, supportedChains)) {
      return currentChain;
    }
    const sessionTargetChainId = window.sessionStorage.getItem("targetChainId");
    const sessionTargetChain = sessionTargetChainId
      ? supportedChains.find(
          (item: any) => item.id.toString() === sessionTargetChainId
        )
      : undefined;
    const defaultChain = supportedChains[0];
    return sessionTargetChain ?? defaultChain;
  };
  // always be supported chain
  const [targetChain, setTargetChain] = React.useState<Chain>(() =>
    getDefaultTargetChain()
  );

  // sync targetChain when user switch network in wallet
  useEffect(() => {
    if (
      !!currentChain &&
      isChainSupported(currentChain, supportedChains) &&
      currentChain.id !== targetChain?.id
    ) {
      setTargetChain(currentChain);
      window.sessionStorage.setItem(
        "targetChainId",
        currentChain.id.toString()
      );
    }
  }, [currentChain, targetChain.id, supportedChains]);

  const isCorrectChain = useMemo(() => {
    if (!isConnected) return true;
    if (!currentChain) return false;
    return currentChain.id === targetChain.id;
  }, [currentChain, targetChain, isConnected]);

  const switchToTargetChain = useCallback(
    (newChainId: number, cb?: () => void) => {
      const newTargetChain = supportedChains.find(
        (chain: any) => chain.id === newChainId
      );
      // unsupported chain
      if (!newTargetChain) {
        toast({
          title: `Chain ${newChainId} is not supported`,
          status: "warning",
          position: "top",
        });
        console.warn(`Chain ${newChainId} is not supported`);
        return;
      }
      // not connected to wallet
      if (!isConnected) {
        setTargetChain(newTargetChain);
        window.sessionStorage.setItem(
          "targetChainId",
          newTargetChain.id.toString()
        );
        toast({
          title: `Connect wallet to get started`,
          status: "warning",
          position: "top",
        });
        return;
      }

      if (currentChain?.id === newTargetChain.id) {
        cb && cb();
        return;
      }
      // switch to a different chain
      if (
        !isPendingSwitchChain &&
        isChainSupported(newTargetChain, supportedChains)
      ) {
        switchChainAsync({ chainId: newTargetChain.id }).then(() => {
          cb && cb();
        });
      }
    },
    [
      switchChainAsync,
      isPendingSwitchChain,
      supportedChains,
      isConnected,
      currentChain?.id,
    ]
  );
  // const { data: blockNumber } = useBlockNumber({ chainId: targetChain.id, watch: true });
  // const { data: nativeBalance, queryKey } = useBalance({
  //   chainId: targetChain.id,
  //   address: accountAddress,
  // });

  // useEffect(() => {
  //   queryClient.invalidateQueries({ queryKey });
  // }, [blockNumber, queryClient, queryKey]);

  return {
    accountAddress,
    isConnected,
    // nativeBalance,
    currentChain,
    targetChain,
    isCorrectChain,
    isPendingSwitchChain,
    switchToTargetChain,
  };
};

const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const wallet = useProviderWallet();
  return (
    <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
  );
};
export default WalletProvider;
