import React, { useEffect } from "react";
import { Header } from "./components/Header";
import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ethPriceAtom, okbPriceAtom } from "./store/price";
import { useSetAtom } from "jotai";
import { getEthPrice, getOKBPrice, getPrices } from "./api/price";
import { contractMap } from "./constants/deployedContracts";

function App() {
  const setEthPrice = useSetAtom(ethPriceAtom);
  const setOkbPrice = useSetAtom(okbPriceAtom);

  useEffect(() => {
    const fn = async () => {
      let time = 1000 * 60 * 5;
      try {
        const prices = await getPrices();
        const ethPrice = prices?.find((price) => {
          return price.symbol === "ETH";
        })!.price;
        const okbPrice = prices?.find((price) => {
          return price.symbol === "OKB";
        })!.price;
        const usdtPrice = prices?.find((price) => {
          return price.symbol === "USDT";
        })!.price;
        ethPrice && setEthPrice(ethPrice);
        okbPrice && setOkbPrice(okbPrice);
      } catch (e) {
        console.log("price error", e);
        time = 1000;
      }
      timer = setTimeout(fn, time);
    };
    let timer = setTimeout(fn);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [setEthPrice, setOkbPrice]);

  useEffect(() => {
    console.log({
      REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV,
      REACT_APP_ANKR_API: process.env.REACT_APP_ANKR_API,
      REACT_APP_BRIDGE_API: process.env.REACT_APP_BRIDGE_API,
      NODE_ENV: process.env.NODE_ENV,
      contractMap,
    });
  }, []);

  return (
    <Box className={"container"}>
      <Header />
      <div className="container-body" style={{ width: "100%" }}>
        <Outlet />
      </div>
    </Box>
  );
}

export default App;
