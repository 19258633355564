import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: "#0a090c00", //change the background
  },
  dialog: {
    // borderRadius: "md",
    bg: `#0a090c`,
  },
});

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
});
