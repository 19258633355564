import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const xxl = defineStyle({
  height: '64px',
  width: '64px',
});

export const spinnerTheme = defineStyleConfig({
  sizes: { xxl },
});
