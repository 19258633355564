import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import logo from "../../assets/images/icon/logo.svg";
import { WalletButton } from "../WalletButton2/WalletButton";
import styles from "./index.module.scss";
import { useIsMobileView } from "../../hooks/useIsMobileView";
import { useSelectedChain } from "../../hooks/useSelectedChain";
import { useWallet } from "../../hooks/wallet-context";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useRef } from "react";
import MobileDawer from "./MobileDawer";
import { useNavigate } from "react-router-dom";

export function Header() {
  const isMobile = useIsMobileView();
  const { from } = useSelectedChain();
  const currentChain = useWallet().currentChain;
  const isNetworkMatch = from.id === currentChain?.id;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<any>();
  const { accountAddress, isConnected } = useWallet();

  const navigate = useNavigate();
  const handlerLoge = () => {
    // navigate("");
    window.location.reload();
  };

  return (
    <Flex className={styles.header} bg="bg.primary">
      <Flex>
        {/* <Image style={{}} src={logo} /> */}
        <Image cursor={"pointer"} onClick={handlerLoge} src={logo} />
        {!isMobile && (
          <Box color="font.primary" ml="98px" cursor="pointer">
            Token
          </Box>
        )}
      </Flex>
      <Flex gap={2}>
        {!isMobile && <WalletButton isHeader />}
        {isMobile && accountAddress && isConnected && (
          <div
            onClick={onOpen}
            style={{ cursor: "pointer" }}
            // onClick={() => setDrawerOpen(true)}
          >
            <HamburgerIcon color={"#FFFFFF"} />
          </div>
        )}
        {isMobile && accountAddress && isConnected && (
          <MobileDawer
            isOpen={isOpen}
            onClose={onClose}
            btnRef={btnRef}
          ></MobileDawer>
        )}
      </Flex>
    </Flex>
  );
}
